import "./App.css";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Footer from "./components/Footer";
import About from "./components/About";
import Services from "./components/Services";
import Contact from "./components/Contact";

function App() {
  return (
    <div className="App">
      <div className="container">
        <div className="navbar">
          <div className="logoTitle">
            <h3 className="logoText">YARKEY</h3>
          </div>
          <div className="navlist">
            <button className="navbutton">Company</button>
          </div>
        </div>
        <div className="contentContainer">
          <section className="hero">
            <div className="hero-text">
              <h1>Yarkey Limited</h1>
              <p>Development and deployment of software solutions.</p>
            </div>
          </section>
          <section className="contact">
            <div className="contact-text">
              <h1>Contact</h1>
              <p>Email: admin@yarkeylimited.com</p>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
